<template>
  <div>
    <Menu />

    <div class="fixed-tags" style="background-color: #e00233; width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;   position: fixed; top: 50%; right: 0; z-index: 1000;">
      <div class="container mt-2 mb-2">
        <div class="row">
          <div class="col-12 text-end">
            <a style="font-size: 20px; color: #fff;" href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank">
              <i class="bi bi-facebook"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.linkedin.com/company/luxury-winelands-estate/about/" target="_blank">
              <i style="color: #fff;" class="bi bi-linkedin"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank">
              <i style="color: #fff;" class="bi bi-instagram"></i>
            </a><br/>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container-fluid pt-3 g-0">
        <div class="row g-0">
          <div class="text-center">
            <div class="contact-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white">
                  <h1 class="display-2"><strong>Contact us</strong></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section class="mt-5">
      <div class="container g-5 pt-3">
        <div class="row g-5">
          <div class="col-md-6 text-lg-end">
            <div>
                <!-- <h6><strong>Contact details</strong></h6> -->
                <h2 class="mb-3"><strong>Let's Get In Touch</strong></h2>
                <p><a style="text-decoration: none;" class="switch-blue" href="tel:+27832581212"><i class="bi bi-telephone-fill"></i> +27 83 258 1212</a></p>
                <p><a style="text-decoration: none;" class="switch-blue" href="mailto:admin.valdevie@everitt.co.za"><i class="bi bi-envelope-fill"></i> admin.valdevie@everitt.co.za</a></p>
                <div class="socials">
                  <!-- <a href="#"><i class="bi bi-facebook switch-blue me-2"></i></a>
                  <a href="#"><i class="bi bi-twitter-x switch-blue me-2"></i></a>
                  <a href="#"><i class="bi bi-youtube switch-blue me-2"></i></a>
                  <a href="#"><i class="bi bi-pinterest switch-blue me-2"></i></a>
                  <a href="#"><i class="bi bi-linkedin switch-blue me-2"></i></a> -->
              </div>
            </div>
          </div>
          <div class="col-md-5 text-start">
            <!-- <h6><strong>Send a message</strong></h6> -->
            <ContactForm class="mt-0 pt-0"/>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
  components: {
    Menu, Footer, ContactForm
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>